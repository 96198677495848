exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-appointment-js": () => import("./../../../src/pages/book-appointment.js" /* webpackChunkName: "component---src-pages-book-appointment-js" */),
  "component---src-pages-direct-access-endoscopy-js": () => import("./../../../src/pages/direct-access-endoscopy.js" /* webpackChunkName: "component---src-pages-direct-access-endoscopy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-thank-you-booking-js": () => import("./../../../src/pages/thank-you-booking.js" /* webpackChunkName: "component---src-pages-thank-you-booking-js" */),
  "component---src-pages-thank-you-dae-js": () => import("./../../../src/pages/thank-you-dae.js" /* webpackChunkName: "component---src-pages-thank-you-dae-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

